import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AlertMessage } from "./alert-message";

interface Props {
  code: string;
  open: boolean;
  onClose: () => void;
}

export const Alert: React.FC<Props> = ({ code, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: "absolute",
          right: 0,
          top: 0,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: "16px 24px" }}>
        <AlertMessage code={code} />
      </DialogContent>
    </Dialog>
  );
};
