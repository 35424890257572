import { useState, useCallback, useContext } from "react";
import { useAppContext } from "hooks/useAppContext";
import { useConversacionContext } from "hooks/useConversacionContext";
import { MessageDto } from "models/MessageDto";
import useFetchApi from "../useFetchApi";

/**
 * Hook encargado de manejar el consumo y procesamiento de mensajes existentes una conversacion
 */
const useMensajes = () => {
  const {
    mensajes,
    setMensajes,
    agregarMensaje,
    mensajesAgrupados,
    setMensajesAgrupados,
    hayMasPaginas,
    setHayMasPaginas,
    isMessageWaiting,
    setIsMessageWaiting,
    ultimoMessageId,
    setUltimoMessageId,
    cargoLista,
    setCargoLista,
    setMensajeEnConstruccion,
    nuevoMensajeArchivos,
    setNuevoMensajeArchivos,
    setIsWaiting,
    nuevoMensajeInput,
    setNuevoMensajeInput,
    nuevoMensajeRunStepError,
    setInputError,
  } = useConversacionContext();

  const fetchAPI = useFetchApi();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOpenAlertError, setIsOpenAlertError] = useState(false);

  const {
    setApiAccesible,
    cuentaSeleccionada,
    conversacionSeleccionada,
    setConversacionSeleccionada,
    setIdConversacion,
    conversaciones,
    debug,
    setDebugInfo,
  } = useAppContext();

  /**
   * Helper encargado de crear un nuevo modelo MessageDto
   * @param id
   * @param content
   * @param isUser
   * @param files
   */
  const createNewMessage = (
    id: string,
    content: string,
    isUser: boolean,
    files?: string[],
    fecha_creacion?: string
  ) => {
    const newMessage = new MessageDto(
      id,
      isUser,
      content,
      files,
      fecha_creacion
    );
    return newMessage;
  };

  /**
   * Busca los mensajes de una conversacion en la api
   */
  const obtenerMensajesApi = useCallback(
    async (conversationId, lastMessageId = null, refresh = false) => {
      let url = `/conversations/${conversationId}`;
      let params = [];
      if (lastMessageId) {
        params.push("last_message_id=" + lastMessageId);
      }

      if (debug) {
        params.push("debug=1");
      }

      if (refresh) {
        params.push("refresh=1");
      }

      if (params.length > 0) {
        url += "?" + params.join("&");
      }

      let data = await fetchAPI(url, "GET");

      if (debug) {
        setDebugInfo((prevDebugInfo) => {
          let debugData = data["debug"];
          // debugData["id"] = conversationId;
          // debugData["conversacion_seleccionada"] = conversacionSeleccionada;

          return {
            ...prevDebugInfo,
            conversacion: debugData,
          };
        });
      }
      return data;
    },
    [debug]
  );

  /**
   * Funcion encargada de cargar los mensajes de una conversacion.
   * Puede recibir el ultimo id de mensaje para poder paginar los resultados
   */
  const cargarMensajes = useCallback(
    async (conversacionId, lastMessageId = null, reset = false) => {
      //console.log("useMensajes - cargarMensajes");
      let _mensajes = [];
      let data = await obtenerMensajesApi(conversacionId, lastMessageId);
      if (!data) {
        return false;
      }

      // @todo: revisar si esto esta ok
      if (data["messages"] && data["messages"].length === 0) {
        setHayMasPaginas(false);
        setIsMessageWaiting(false);
        return;
      }

      let mensajesProcesados = [];
      let ultimoMensajeId = null;

      for (let i = 0; i < data["messages"].length; i++) {
        if (!ultimoMensajeId && data["messages"][i]["rol"] !== "User") {
          continue;
        } else if (!ultimoMensajeId && data["messages"][i]["rol"] === "User") {
          ultimoMensajeId = data["messages"][i]["id"];
        }

        mensajesProcesados.push(
          createNewMessage(
            data["messages"][i]["id"],
            data["messages"][i]["contenido"],
            data["messages"][i]["rol"] !== "Assistant",
            data["messages"][i]["archivos"],
            data["messages"][i]["fecha_creacion"]
          )
        );
      }

      if (!reset) {
        mensajesProcesados = [...mensajesProcesados, ...mensajes];
      }

      setUltimoMessageId(ultimoMensajeId);
      setMensajes(mensajesProcesados);
      setHayMasPaginas(
        data["messages"].length > 5 && data["has_more_messages"]
      );
      setIsMessageWaiting(false);
      setIsWaiting(false);
    },
    [cuentaSeleccionada, conversacionSeleccionada, conversaciones]
  );

  /**
   * Metodo encargado de agrupar los mensajes en base al rol del que lo envia
   */
  const agruparMensajes = useCallback(() => {
    //console.log("useMensajes - agruparMensajes");
    if (mensajes.length === 0) {
      setMensajesAgrupados([]);
      return;
    }

    let lastRole = null;
    let currentGroup = [];
    const groupedMessages = [];

    mensajes.forEach((message) => {
      if (message.isUser === lastRole) {
        // Si el rol del mensaje actual es el mismo que el del último rol procesado,
        // agregar el mensaje al grupo actual
        currentGroup.push(message);
      } else {
        // Si el rol del mensaje actual es diferente al último rol procesado,
        // iniciar un nuevo grupo de mensajes y actualizar el último rol procesado
        if (currentGroup.length > 0) {
          groupedMessages.push(currentGroup);
        }
        currentGroup = [message];
        lastRole = message.isUser;
      }
    });

    // Asegurarse de agregar el último grupo de mensajes
    if (currentGroup.length > 0) {
      groupedMessages.push(currentGroup);
    }

    // console.log(groupedMessages);
    setMensajesAgrupados(groupedMessages);
  }, [mensajes]);

  /**
   * Se encarga de traer los ultimos mensajes del asistente para agregarlos a la lista de mensajes
   * @param conversacionId
   * @param eliminarUltimo
   */
  const refrescarListaDeMensajes = useCallback(
    async (conversacionId, eliminarUltimo = false) => {
      const refresh = eliminarUltimo;
      let data = await obtenerMensajesApi(conversacionId, null, refresh); // eliminar ultimo es sinonimo de refresh
      setNuevoMensajeArchivos([]);
      setIsWaiting(false);

      // actualizo los ultimos mensajes mientras sean del asistente
      let maxIndex = null;
      if (!data || !data["messages"]) {
        return;
      }

      for (let i = data["messages"].length - 1; i >= 0; i--) {
        if (data["messages"][i]["rol"] !== "Assistant") {
          maxIndex = i;
          break;
        }
      }

      let limit = data["messages"].length;

      if (eliminarUltimo) {
        if (!nuevoMensajeRunStepError) {
          setMensajeEnConstruccion(null);
        }
      }

      for (let i = maxIndex + 1; i < limit; i++) {
        setMensajes((prevMensajes) => [
          ...prevMensajes,
          createNewMessage(
            data["messages"][i]["id"],
            data["messages"][i]["contenido"],
            data["messages"][i]["rol"] === "User",
            data["messages"][i]["archivos"],
            data["messages"][i]["fecha_creacion"]
          ),
        ]);
      }
    },
    [mensajes, nuevoMensajeRunStepError]
  );

  /**
   * Envia el mensaje. Se le puede pasar el input del string pero sino utilizará el state del contexto.
   * El soporte es para poder utilizarlo en acciones como iniciar pregunta.
   */
  const enviarMensaje = useCallback(
    async (mensaje: string = null) => {
      const primerMensaje = mensajes.length === 0;
      let conversacionCreada = {};

      // mecanismo fallback para levantarlo de la variable de estado
      if (!mensaje) {
        mensaje = nuevoMensajeInput;
      }

      // limpio y si no valida genero el shake y muestro un error
      if (mensaje.trim() === "") {
        setInputError(true);
        setTimeout(() => setInputError(false), 2000); // Remueve el error después de 2 segundos
        return false;
      }

      // limpio el input
      setNuevoMensajeInput("");

      // activo el loader
      setIsWaiting(true);

      // agrego un nuevo mensaje del usuario
      setMensajes((prevMensajes) => [
        ...prevMensajes,
        crearNuevoMensajeUsuario(mensaje),
      ]);

      // si es el primer mensaje primero debemos iniciar la conversacion
      if (primerMensaje) {
        conversacionCreada = await fetchAPI(
          `/conversations/start/${cuentaSeleccionada.id}`
        );
        if (conversacionCreada) {
          setConversacionSeleccionada({
            id: conversacionCreada["conversation_id"],
            titulo: "Nueva conversación",
            es_prueba: false,
            activo: true,
            assistant_id: "",
            fecha_creacion: "",
          });

          setIdConversacion(conversacionCreada["conversation_id"]);
        }
      }

      // envio el mensaje y le agrego si es primer mensaje
      let conversacionId = primerMensaje
        ? conversacionCreada["conversation_id"]
        : conversacionSeleccionada.id;

      let url = `/conversations/${conversacionId}/send-message`;
      if (primerMensaje) {
        url += "?first_message=1";
      }

      // creo el formdata y asigno valores
      const formData = construirBodyFormulario(mensaje, nuevoMensajeArchivos);

      // creo el mensaje en construccion
      let mensajeConstruccion = createNewMessage("xxxx-xxxxx", "", false, []);
      setMensajeEnConstruccion(mensajeConstruccion);

      try {
        const data = await fetchAPI(url, "POST", formData);
        if (!data || !data.message) {
          return;
        }

        // inicio una llamada para ver el estado del mensaje
        const userMessageId = data.message.id;
        actualizarIdUltimoMensajeUsuario(userMessageId);
        const runId = data.run.id;
        if (runId && runId != 0) {
          let runData = await fetchAPI(
            `/conversations/${conversacionId}/runs/${runId}`
          );

          while (
            runData.run.status === "in_progress" ||
            runData.run.status === "queued" ||
            runData.run.status === "requires_action"
          ) {
            // Esperar y volver a consultar
            await new Promise((resolve) => setTimeout(resolve, 5000));
            runData = await fetchAPI(
              `/conversations/${conversacionId}/runs/${runId}`
            );
          }
        }

        setIsWaiting(false);
      } catch (error) {
        setError(error.message);
        setIsOpenAlertError(true);
        return;
      }

      return conversacionId;
    },
    [cuentaSeleccionada, conversacionSeleccionada, mensajes, nuevoMensajeInput]
  );

  /**
   * Metodo para generar un nuevo mensaje del usuario. Carga la fecha de creación.
   * @param mensaje
   */
  const crearNuevoMensajeUsuario = (mensaje) => {
    const timestamp = Math.floor(Date.now() / 1000);
    let fecha_creacion = timestamp.toString();

    return createNewMessage("xxx-new", mensaje, true, [], fecha_creacion);
  };

  /**
   * Genera el body del formulario para enviar un mensaje
   * @param mensaje
   * @param archivos
   */
  const construirBodyFormulario = (mensaje, archivos) => {
    let formData = new FormData();
    formData.append("message", mensaje);

    // agrego los archivos del dropzone
    archivos.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });

    return formData;
  };

  const actualizarIdUltimoMensajeUsuario = (nuevoId) => {
    setMensajes((prevMensajes) => {
      const messageIndex = prevMensajes.findIndex(
        (message) => message.id === "xxx-new"
      );
      if (messageIndex >= 0) {
        const updatedMessages = [...prevMensajes];
        updatedMessages[messageIndex] = {
          ...updatedMessages[messageIndex],
          id: nuevoId,
        };
        return updatedMessages;
      }
      return prevMensajes;
    });
  };

  return {
    error,
    setError,
    isOpenAlertError,
    setIsOpenAlertError,
    mensajes,
    setMensajes,
    isLoading,
    hasMore,
    cargarMensajes,
    agruparMensajes,
    refrescarListaDeMensajes,
    createNewMessage,
    enviarMensaje,
  };
};

export default useMensajes;
