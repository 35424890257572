import React from "react";
import { Text } from "components/Text";

interface Props {
  code: string;
}

export const AlertMessage: React.FC<Props> = ({ code }) => {
  switch (code) {
    case "user_error":
    case "no_message":
      return (
        <Text variant="p3">
          Lo sentimos, no encontramos información relacionada con tu consulta.
          Por favor, contacta al soporte de Agrology a través de&nbsp;
          <a href="mailto:contacto@agrology.com">contacto@agrology.com</a> para
          que podamos asistirte.
        </Text>
      );
    default:
      return (
        <Text variant="p3">
          Lo sentimos, ocurrió un error al procesar tu mensaje. Por favor,
          intenta nuevamente. Error: {code}
        </Text>
      );
  }
};
